import React, { useContext } from 'react'
import { ButtonContent } from '../global/boton/ButtonContent';
import { GlobalDataContext } from '../../context/context';
import { VscDebugBreakpointData } from 'react-icons/vsc';
import imgDefault from '../../assets/image/placeholder.png'
import { BsBoxArrowInRight } from 'react-icons/bs';

const Block_11 = ({ image1,image2, listsAbout, sloganPrincipal, title, text, listsServices }) => {
    const { rpdata } = useContext(GlobalDataContext);

    return (
        <>
            <div className='w-full h-auto flex md:flex-row flex-col-reverse my-20'>
                <div className='md:w-[55%] w-full h-auto md:h-auto relative py-10 px-6 md:px-12'>
                    <div className='w-[300px] h-[300px] top-[-10%] left-[-5%] absolute rounded-full bg1 -z-10'></div>
                    <div className='z-10 w-full h-auto flex items-center mb-3'>
                        {
                            sloganPrincipal ?
                                rpdata?.dbPrincipal?.licensed ?
                                    <h2 className='pb-3 capitalize bH1'>
                                        {rpdata?.dbPrincipal?.licensed}
                                    </h2>
                                    : <h2 className='pb-10 capitalize bH1'>
                                        {
                                            rpdata?.dbPrincipal?.exprYears ?
                                                `Tenemos Muchos años de trayectoria musical`
                                                : 'we have many years of experience'
                                        }
                                    </h2>
                                :
                                <h2 className='bH2 pb-3 capitalize'>
                                    {
                                        title ? title :
                                            <span className='lowercase text-[18px]'>
                                                {`default title={'frase'}`} o
                                                {` agregar licencia o años de experienceias sloganPrincipal={true}`}
                                            </span>
                                    }
                                </h2>
                        }
                    </div>
                    <div className='px-10'>
                        <p>
                            {
                                text ? text :
                                    <span>{`para agregar el texto -> text={'description'}`}
                                        <br />{'para agregar lista de about -> listsAbout={true}'}
                                        <br />{'para agregar lista de servicios -> listsServices={true}'}
                                    </span>
                            }
                        </p>
                        {
                            listsAbout ?
                                <ul className='grid grid-cols-1 md:grid-cols-2 pb-5'>
                                    {
                                        rpdata?.dbAbout?.[0].list.length > 1 ?
                                            rpdata?.dbAbout?.[0].list.map((item, index) => {
                                                return (

                                                    <li key={index} className="py-2 flex items-center">
                                                        <VscDebugBreakpointData />
                                                        <span className="pl-2">{item}</span>
                                                    </li>
                                                )
                                            })
                                            : null
                                    }
                                </ul>
                                : null
                        }
                        {
                            listsServices ?
                                <ul className='grid grid-cols-1 md:grid-cols-2 pb-5'>
                                    {rpdata?.dbServices?.slice(0, 6).map((item, index) => {
                                        return (
                                            <li key={index} className=" flex items-center">
                                                <BsBoxArrowInRight />
                                                <span className='pl-2'>{item.name}</span>
                                            </li>
                                        )
                                    })}
                                </ul>
                                : null
                        }
                        <ButtonContent />
                    </div>
                </div>
                <div className='md:w-[45%] w-full h-auto flex items-center justify-center space-x-0 md:space-x-36 relative overflow-visible py-8'>
                    <div className='md:w-[400px] md:h-[400px] w-[300px] h-[300px] md:-bottom-16 -bottom-[53.5%] md:-right-16 right-24 absolute rounded-full bg1 -z-10 block '></div>
                    <div className='md:w-[110px] md:h-[110px] w-[80px] h-[80px] md:top-12 top-10 md:left-4 -left-2 absolute rounded-full border-[15px] borderColor'></div>
                    <div className='absolute md:block hidden bottom-8 -left-20 w-[30%] h-[50%] rounded-3xl border-white border-[6px] shadow-2xl -rotate-[12deg] overflow-hidden'>
                        <img
                            src={image1 ? image1 : imgDefault}
                            alt='no found'
                            loading='lazy'
                            className='w-[700px] h-[800px] md:h-full object-cover'
                        />
                    </div>
                    <div className='md:w-[45%] w-[300px] md:h-[90%] h-[300px] rounded-3xl border-white border-[6px] shadow-2xl -rotate-[12deg] overflow-hidden'>
                        <img
                            src={image2 ? image2 : imgDefault}
                            alt='no found'
                            loading='lazy'
                            className='w-full h-full md:h-full object-cover'
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Block_11