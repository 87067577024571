import React, { useContext, useState } from 'react'
import { GlobalDataContext } from '../../../context/context'
import { ButtonContent } from '../boton/ButtonContent'
import Navmenu from './MenuList'
import { MdClose, MdMenuOpen } from 'react-icons/md'




const HeaderTwo = () => {

    const { rpdata } = useContext(GlobalDataContext)
    const [openHMenu, setHMenu] = useState(false)

    const handleClick = () => {
        return setHMenu(!openHMenu)
    }

    return (
        <header className='bg-white w-full relative md:absolute top-0 z-50'>
            <div className='flex'>
                <div className='md:pl-14 md:py-0 py-5 w-[100%] h-[60%] md:h-auto mr-0 md:w-[35%] lg:w-[25%] lg:mr-10 flex md:justify-start justify-center items-center' onClick={handleClick}>
                    {
                        openHMenu ?
                            <MdClose fontSize={45} color={'black'} />
                            :
                            <MdMenuOpen fontSize={45} color={'black'} />
                    }
                </div>
                <div className='hidden md:block w-[65%] lg:w-[95%] h-50 self-center'>
                    <div className='flex items-center'>
                        <div className='w-[55%] lg:w-[85%] text-center '>
                            <h1 className='textslide3 text-[40px]'>{rpdata?.dbPrincipal?.name}</h1>
                        </div>
                        <div className='w-[45%] lg:w-[25%] text-end border-l ml-0 lg:ml-3 pr-5 items-center'>
                            <ButtonContent />
                        </div>
                    </div>
                </div>
            </div>

            {
                openHMenu ?
                    <div className='fixed top-0 z-50 w-full h-full md:h-screen bg-[#00000086] ease-in-out duration-300'>
                        <div className='w-full bg-slate-100 md:w-[25%] h-full overflow-y-auto menuScroll'>
                            <div className='flex justify-between w-full py-4 mx-auto items-center bg-white rounded-b-xl'>
                                <div className=''>
                                    <img
                                        src={rpdata?.dbPrincipal?.logo}
                                        alt='logo'
                                        loading='lazy'
                                        className='w-[70%]'
                                    />
                                </div>
                                <div className='bg1 rounded-lg mx-3' >
                                    <MdClose color='white' fontSize={40} onClick={handleClick} />
                                </div>
                            </div>
                            <div className='w-full py-6 h-auto'>
                                <Navmenu classes={'flex flex-col px-4'}/>
                            </div>
                        </div>
                    </div>
                    :
                    null
            }

            {/* {
                rpdata?.simpleWidgets?.[3]?.activo ?
                    null
                    :
                    <div className='bg-white py-4 mx-auto justify-evenly flex'>
                        <div className='flex justify-end items-center'>
                            <nav>
                                <Navmenu />
                            </nav>
                            <ul className="flex justify-center space-x-7 ml-10">
                                {rpdata?.dbSocialMedia?.redes.map((item, index) => {
                                    return (
                                        <li key={index} className="textColor2">
                                            <a href={item.url} target="_blank" rel='noopener noreferrer'>
                                                <i
                                                    className={`fab fa-${item.icon}`}
                                                    aria-hidden="true"
                                                />
                                            </a>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
            } */}
        </header>
    )
}

export default HeaderTwo